.front-page {
    position: relative;
    // overflow: hidden;
    // z-index: 0;

    h2 {
        font-size: 3.750rem;
        font-family: $font-family--spe-heading;
        font-weight: $font-weight--spe-heading;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: $color-second;
        -webkit-text-fill-color: $color-second;
    }

}

main .wrapper .front-page {
    h2 {
        padding-bottom: 10px;
        letter-spacing: -0.005em;
        margin: 50px 0 35px;

        &:after {
            background-image: url('/wp-content/themes/frepillon/assets/src/images/sprite-images/autres/virgule-titre_home.svg');
            width: 101px;
            height: 17px;
        }
    }
}

[class*="--notimg"] {
    background: $color-bg--image;
    background-image: url(/wp-content/themes/noyau/assets/images/icon-post.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.home {

    &__button {
        margin: 0;
        width: 267px;

        &--absolute {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1;
        }
    }

}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    &::before {
        content: '';
        background: url('/wp-content/themes/frepillon/assets/src/images/motif.png');
        width: calc(((100% - 1170px) / 2) + 1260px);
        height: 800px;
        position: absolute;
        right: -1030px;
        bottom: 150px;
        z-index: -1;
    }

    .swiper-container {
        height: 700px;
        width: calc(((100% - 1170px) / 2) + 940px);
        margin-left: 0;
        margin-right: 0;

        .swiper-slide {
            img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 550px;
                object-position: top;
            }
        }

        .container--pagination {
            position: absolute;
            left: calc((100vw - 1170px) / 2);
            bottom: 150px;
            height: 50px;
            width: 120px;
            max-width: 100%;
            pointer-events: none;
            transform: translateY(50%);
            z-index: 1;

            div {
                pointer-events: auto;
                width: 50px;
                height: 50px;
                background: $color-main;
                border: 2px solid $color-main;
                border-radius: 50px;
                margin: 0;
                top: 0;
                transition: all $duration;

                &::after {
                    position: relative;
                    font-size: $font-size--text;
                    font-weight: $font-weight-bold;
                    color: $color-text;
                }

                &.swiper-button-prev {
                    left: 0;
                }
                
                &.swiper-button-next {
                    right: 0;
                }

                &:hover {
                    background: $color-white;
                }
            }
        }
    }

    .slideshow {
        display: inherit;
        position: relative;
        width: 100%;
        height: 100%;

        .container--slideshow {
            position: relative;
            height: 100%;
            margin: 0;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            left: calc((100vw - 1170px) / 2);
            bottom: 0;
            width: calc(1100px - 165px);
            height: 150px;
            padding: 0;
            background: $color-white;
            color: $color-text;
        }

        &__title {
            margin: 40px 0 10px;
            font-size: 1.625rem;
            font-weight: $font-weight-bold;
            line-height: 1.2;
        }
    }

    .video-container {
        height: 700px;
        width: 100%;

        .slideshow {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            video,
            img {
                position: absolute;
                width: 100%;
                height: 550px;
                object-fit: cover;
                object-position: top;
            }

            img {
                display: none;
            }
        }
    }
}


//======================================================================================================
// Acces Rapides
//======================================================================================================

.home .home__access {
    position: absolute;
    top: 27px;
    right: calc((100% - 1170px) / 2);
    z-index: 9;

    &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: calc(145px * 2 + 30px);
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 145px;
        height: 145px;
        padding: 11px;
        margin-bottom: 30px;
        background: $color-white;
        position: relative;
        border-radius: 0px 20px;
        box-shadow: $shadow;

        .label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: $font-weight-medium;
            font-size: $font-size--text-small;
            text-transform: uppercase;
            height: 32px;
            line-height: 1.2;
        }

        &:hover {
            padding: 20px 11px;
            box-shadow: $shadow--hover;
        }
    }

    &__svg-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
    }

    &__item {
        &:nth-last-child(-n+2)  {
            .home__access__link {
                margin-bottom: 0;
            }
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.home .news {
    position: relative;
    margin-bottom: 80px;
    background: url('/wp-content/themes/frepillon/assets/src/images/motif.png');
    overflow: hidden;

    &__container {
        position: relative;
        // z-index: 2;

        &::before {
            content: "";
            background: $color-white;
            width: 3000px;
            height: 400px;
            z-index: 0;
            position: absolute;
            top: -150px;
            left: 50%;
            transform: translateX(-50%) skewY(-4deg);
        }
    }

    &__list {
        display: grid;
        grid-template-columns: 470px 670px;
        grid-gap: 30px;
        z-index: 2;
        position: relative;
    }

    &__item {
        background: $color-white;

        &__content {
            display: flex;
            position: relative;
            box-shadow: $shadow;
            width: 670px;
            height: 195px;
            transition: all $duration;
        }
        
        img {
            width: 200px;
            height: 100%;
            object-fit: cover;
            transition: all $duration;
        }
        
        &__infos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            padding: 55px 30px 30px 30px;
            background: $color-white;

            &__taxonomy {
                background: $color-bg-grayblue;
                height: 26px;
                position: absolute;
                left: 0;
                top: 15px;
                border-radius: 0 50px 50px 0;
                padding-left: 30px;
                transition: all $duration;
            }

            &__title {
                color: $color-text;
                margin: 0 0 10px 0;
                line-height: 1.1;
            }

            &__introduction {
                line-height: 1.4;
            }

        }

        [class*="--notimg"] {
            background-position: 50% 30%;
        }

        &--big {
            grid-column: 1;
            grid-row: span 2;

            .news__item {
        
                &__content {
                    width: 470px;
                    height: 420px;
                }

                &__infos {
                    width: 100%;
                    height: 180px;
                    padding: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    color: $color-white;
                    background: $color-bg-gradient-actu;
                    opacity: 0.92;

                    &__taxonomy {
                        background: $color-main;
                        top: -13px;
                    }   
        
                    &__title {
                        color: $color-white;
                    }
        
                }

                
            }
            
            img {
                width: 100%;
            }

            [class*="--notimg"] {
                background-position: 50% 30%;
            }
            
        }

        &:hover {
            .news__item {

                &__content {
                    box-shadow: $shadow--hover;
                }

                &__infos {
                    &__taxonomy {
                        padding-left: 100px;
                    }
                }

            }
        }

    }

}

//======================================================================================================
// Agenda
//======================================================================================================

.home .events {
    margin-bottom: 60px;
    height: 596px;

    .swiper-container {
        width: calc(100% + 25px);
        padding: 15px;
        margin-left: -15px;
        margin-top: -15px;
        overflow: hidden;
    }

    .swiper-slide {
        width: 368px;
        margin-right: 30px;
    }

    &__container {
        position: relative;

        &:before {
            content: '';
            background: $color-bg-grayblue;
            width: 3000px;
            height: 880px;
            z-index: 1;
            position: absolute;
            top: -220px;
            left: 50%;
            transform: translateX(-50%) skewY(7deg);
        }
    }

    &__title {
        z-index: 1;
    }

    &__navigation {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 120px;
        top: 15px;
        left: 250px;

        .swiper-button-prev,
        .swiper-button-next {
            position: relative;
            background: $color-third;
            left: unset;
            right: unset;
            margin: 0;
            width: 50px;
            height: 50px;
            border: 2px solid $color-third;
            border-radius: 50px;
            transition: all $duration;

            &:after {
                display: none;
            }

            &:hover {
                background: $color-white;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__item {

        &__link {
            width: 370px;
            height: 420px;
            display: flex;
            flex-direction: column;
            box-shadow: $shadow;

            &:hover {
                color: $color-text;
                box-shadow: $shadow--hover;

                .events__item {
                    &__image__container {
                        &::before {
                            transform: translateX(-50%) skewY(7deg);
                        }

                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image {
            &__container {
                width: 100%;
                height: 320px;
                position: relative;
                overflow: hidden;

                &::before {
                    content: '';
                    background: $color-white;
                    transition: all $duration;
                    position: absolute;
                    width: 100%;
                    height: 60px;
                    bottom: -32px;
                    left: 50%;
                    transform: translateX(-50%) skewY(-7deg);
                    z-index: 1;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all $duration;
                }
            }
        }

        &__date {
            background: $color-third;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            border-radius: 50px;
            min-width: 165px;
            height: 77px;
            padding: 0 45px;
            top: -67px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;

            p {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            svg {
                width: 40px;
                height: 40px;
            }

            &__day {
                font-family: $font-family--heading;
                font-size: $font-size--2;
                font-weight: $font-weight-medium;
                line-height: 1.1;
            }

            &__month {
                font-size: $font-size--text-small;
                text-transform: uppercase;
            }

        }

        &__infos {
            width: 100%;
            height: 100px;
            padding: 15px 30px;
            background: $color-white;
            position: relative;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            &__title {
                color: $color-text;
                margin: 0;
            }

        }


    }

    .swiper-pagination {
        left: 50%;
        transform: translateX(-50%);
        margin-top: 20px;
        position: relative;

        &-bullet {
            width: 15px;
            height: 15px;
            margin-right: 15px;
            background: $color-text;
            opacity: 1;

            &:last-of-type {
                margin-right: 0;
            }

            &-active {
                background: $color-third;
            }
        }
    }    

}

//======================================================================================================
// Publications
//======================================================================================================

.documents__adminarea {

    &__container {
        display: flex;
        justify-content: space-between;
        position: relative;
    
        &::before {
            content: '';
            background: url(/wp-content/themes/frepillon/assets/src/images/motif.png);
            width: 3000px;
            height: 300px;
            z-index: -1;
            position: absolute;
            bottom: -180px;
            left: 50%;
            transform: translateX(-50%) skewY(7deg);
        }
    }
}

main .wrapper .front-page .documents__title {
    margin-top: 0;
}

.home .documents {
    padding-bottom: 44px;
    width: 570px;

    .swiper-container {
        width: calc(100% + 30px);
        padding: 15px;
        margin-left: -15px;
        margin-top: -15px;
        overflow: hidden;
        pointer-events: none;
    }

    &__title {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-size: cover;
            background-image: url('/wp-content/themes/frepillon/assets/src/images/sprite-images/autres/virgule-titre_css.svg');
            width: 82px;
            height: 8px;
        }
    }

    &__navigation {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 120px;
        top: 15px;
        left: 315px;

        .swiper-button-prev,
        .swiper-button-next {
            position: relative;
            background: $color-main;
            left: unset;
            right: unset;
            margin: 0;
            width: 50px;
            height: 50px;
            border: 2px solid $color-main;
            border-radius: 50px;
            transition: all $duration;

            &:after {
                display: none;
            }

            &:hover {
                background: $color-white;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__item {
        display: flex;

        &__infos {
            width: 270px;
            margin-left: 30px;
            height: 295px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__title {
            margin: 0 0 15px 0;
            color: $color-text;
            font-weight: $font-weight-bold;
            pointer-events: all;
        }

        &__extension {
            text-transform: uppercase;
        }

        &__buttons {
            margin-top: 30px;
            pointer-events: all;
        }

        &__button {
            width: 48px;
            height: 46px;
            padding: 0;
            border-radius: 20px 0px;
            border: 2px solid $color-second;
            background: $color-second;
            margin: 0 20px 0 0;

            svg {
                width: 35px;
                height: 35px;
                fill: $color-white;
            }

            &:last-of-type {
                margin-right: 0;
            }

            &:hover {
                background: $color-white;

                svg {
                    fill: $color-text;
                }
            }
        }

        &__image__container {
            width: 270px;
            height: 384px;
            box-shadow: $shadow;
            pointer-events: all;

            &--no-image {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-bg--image;
            
                svg {
                  width: 40%;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    &__button {
        border-radius: 0px 0px 30px 0px;
        padding: 18px 30px 18px 70px;
        position: absolute;
        bottom: 90px;
        left: 240px;
        margin: 0;
    }

}

//======================================================================================================
// AdminAreas
//======================================================================================================
.admin-blocs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    width: 570px;
    height: 560px;

    .bloc {
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &__wrapper {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;


            &:hover {
                img {
                    transform: scale(1.1);
                }

                .bloc__title {
                    padding: 0 30px 0 60px;
                }
            }

            &--video {
                cursor: pointer;

                &::before {
                    content: '\f126';
                    font: normal 34px/1 dashicons;
                    position: absolute;
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    color: $color-white;
                }
            }

        }

        &__image {
            position: absolute;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: all .25s;
            }
        }

        &__content {
            position: absolute;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 115px;
            padding: 20px 30px 0;
            color: $color-white;
            transition: all 0.5s;
            bottom: 0;
            left: 0;

            &::before {
                content: '';
                background: $color-bg-gradient-actu;
                opacity: 0.92;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: -1;
                top: 0;
            }
        }

        &__title {
            font-size: 1.375rem;
            font-weight: $font-weight-medium;
            background: $color-third;
            color: $color-text;
            display: flex;
            align-items: center;
            position: absolute;
            border-radius: 0px 30px 30px 0px;
            margin: 0;
            padding: 0 30px;
            height: 63px;
            bottom: 90px;
            left: 0;
            transition: all .25s;
        }

        .popup-area {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 20;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            background: $color-black--rgba;

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }

            .accordion {
                height: 0;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }

            .close-area-popup {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: absolute;
                top: 40px;
                right: calc((100% - 1170px)/2);
                width: 40px;
                height: 40px;
                background: $color-white;
                border-radius: 50px;

                &::before {
                    content: '\f335';
                    font: normal 30px/1 'dashicons';
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    // Start Slideshow 1200
    .home-slideshow {
    
        &::before {
            width: calc(((100% - 940px) / 2) + 1260px);
            right: -1060px;
            bottom: 140px;
            transform: skewX(-2deg);
        }
    
        .swiper-container {
            height: 610px;
            width: calc(((100% - 940px) / 2) + 730px);
    
            .swiper-slide {
                img {
                    height: 470px;
                }
            }
    
            .container--pagination {
                left: calc((100vw - 940px) / 2);
                bottom: 140px;
            }
        }
    
        .slideshow {
    
            &__content {
                left: calc((100vw - 940px) / 2);
                bottom: 0;
                width: calc(830px - 130px);
                height: 140px;
            }
    
            &__title {
                font-size: 1.5rem;
            }
        }
    
        .video-container {
            height: 660px;
    
            .slideshow {
                video,
                img {
                    height: 470px;
                }
            }
        }
    }
    // End Slideshow 1200
    
    // Start Acces Rapides 1200
    .home .home__access {
        top: 17px;
        right: calc((100% - 940px) / 2);
    
        &__list {
            width: calc(132px * 2 + 20px);
        }
    
        &__link {
            width: 132px;
            height: 132px;
            margin-bottom: 20px;
        }
    
        &__item {
            &:nth-last-child(-n+2)  {
                .home__access__link {
                    margin-bottom: 0;
                }
            }
        }
    }
    // End Acces Rapides 1200

    // Start Actualités 1200
    .home .news {
    
        &__list {
            grid-template-columns: 380px 540px;
            grid-gap: 30px 20px;
        }
    
        &__item {
    
            &__content {
                width: 540px;
            }
            
            img {
                width: 180px;
            }
            
            &__infos {
                padding: 55px 20px 20px 20px;
    
                &__taxonomy {
                    padding-left: 20px;
                }
    
                &__title {
                    font-size: 1.5rem;
                }
    
            }
    
            &--big {
    
                .news__item {
            
                    &__content {
                        width: 380px;
                        height: 420px;
                    }
    
                    &__infos {
                        padding: 20px;
                    }
                    
                }

                img {
                    width: 100%;
                }
                
            }
    
            &:hover {
                .news__item {
                    &__infos {
                        &__taxonomy {
                            padding-left: 80px;
                        }
                    }
                }
            }
    
        }
    
    }
    // End Actualités 1200

    // Start Agenda 1200
    .home .events {
        height: 576px;

        .swiper-slide {
            width: 292px;
            margin-right: 30px;
        }

        &__container {
            &::before {
                top: -240px;
            }
        }

        &__navigation {
            left: 240px;
        }
    
        &__item {
    
            &__link {
                width: 300px;
                height: 400px;
            }
    
            &__image {
                &__container {
                    height: 260px;
                }
            }
    
            &__infos {
                height: 140px;
            }
    
        } 
    
    }
    // End Agenda 1200

    // Start Publications 1200
    .home .documents {
        width: 460px;
    
        &__navigation {
            left: 315px;
        }
    
        &__item {
    
            &__infos {
                width: 220px;
                margin-left: 20px;
                height: 250px;
            }
    
            &__title {
                font-size: $font-size--4;
            }

            &__image__container {
                width: 220px;
                height: 312px;
            }
    
        }
    
        &__button {
            bottom: 75px;
            left: 183px;
        }
    
    }
    // End Publications 1200

    // Start Admin Area 1200
    .admin-blocs {
        width: 460px;
        height: 500px;
    
        .bloc {
    
            &__title {
                font-size: 1.25rem;
                bottom: 84px;
            }
    
        }
    }
    // End Admin Area 1200
    
    
}


// 960
@media screen and (max-width: $medium) {

    main .wrapper .front-page h2 {
        margin: 70px 0 35px;
    }

    // Start Slideshow 960
    .home-slideshow {
    
        &::before {
            width: calc(((100% - 620px) / 2) + 1260px);
            bottom: 160px;
        }
    
        .swiper-container {
            height: 560px;
            width: 100%;
    
            .swiper-slide {
                img {
                    height: 400px;
                }
            }
    
            .container--pagination {
                left: calc((100vw - 620px) / 2);
                bottom: 160px;
            }
        }
    
        .slideshow {
            &__content {
                left: calc((100vw - 620px) / 2);
                bottom: 0;
                width: 620px;
                height: 160px;
            }
        }
    
        .video-container {
            .slideshow {
                video {
                    display: none;
                }
                img {
                    display: block;
                    height: 400px;
                }
            }
        }
    }
    // End Slideshow 960

    // Start Acces Rapides 960
    .home .home {
        &__access {
            position: relative;
            width: 640px;
            padding: 0 10px;
            margin: 0 auto;
            right: 0;

            &__list {
                width: 100%;
                margin-top: 20px;
            }

            &__link {
                width: 190px;
                height: 132px;
                margin-bottom: 25px;
            }
        
            &__item {
                &:nth-last-child(-n+3)  {
                    .home__access__link {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    // End Acces Rapides 960

    // Start Actualités 960
    .home .news {
    
        &__list {
            grid-template-columns: 620px;
            grid-template-rows: 470px repeat(2, 195px);
            grid-gap: 30px;
        }
    
        &__item {
    
            &__content {
                width: 620px;
            }
            
            &--big {
                grid-row: span 1;
                
                .news__item {
                    
                    &__content {
                        width: 620px;
                        height: 470px;
                    }
    
                    &__infos {
                        padding: 20px;
                        height: 200px;
                    }
                    
                }

            }
    
        }
    
    }
    // End Actualités 960

    // Start Agenda 960
    .home .events {
        margin-bottom: 20px;

        &__container {
            &::before {
                height: 1100px;
                top: -465px;
            }
        }

        &__navigation {
            left: 195px;
        }
    }
    // End Agenda 960

    // Start Publications 960
    main .wrapper .front-page .documents__title {
        margin-top: 60px;
    }

    .documents__adminarea__container {
        flex-direction: column-reverse;
    }

    .home .documents {
        width: 620px;
        position: relative;

        &__navigation {
            top: 75px;
        }
    
        &__item {
    
            &__infos {
                width: 300px;
                height: 320px;
            }

            &__image__container {
                width: 300px;
                height: 425px;
            }
    
        }
    
        &__button {
            bottom: 105px;
            left: 263px;
        }
    
    }
    // End Publications 960

    // Start Admin Area 960
    .admin-blocs {
        width: 620px;
        height: 620px;
    }
    // End Admin Area 960

}


// 640
@media screen and (max-width: $small) {

    main .wrapper .front-page h2 {
        text-align: center;

        &:after {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .home__button--absolute {
        position: relative;
        width: 300px;
        top: unset;
        right: 0;
        margin-top: 30px;
        z-index: 10;
    }

    // Start Slideshow 640
    .home-slideshow {

        &::before {
            display: none;
        }
    
        .swiper-container {
            height: 450px;
            width: 100%;
    
            .swiper-slide {
                img {
                    height: 270px;
                }
            }
    
            .container--pagination {
                left: 50%;
                bottom: 155px;
                transform: translateX(-50%);
            }
        }
    
        .slideshow {
            &__content {
                left: calc((100vw - 300px) / 2);
                bottom: 0;
                width: 300px;
                height: 180px;
                text-align: center;
            }
        }
    
        .video-container {
            .slideshow {
                video {
                    display: none;
                }
                img {
                    display: block;
                    height: 270px;
                }
            }
        }
    }
    // End Slideshow 640

    // Start Acces Rapides 640
    .home .home {
        &__access {
            width: 320px;
            padding: 0 10px;

            &__list {
                margin-top: 30px;
            }

            &__link {
                width: 132px;
                height: 132px;
                margin-bottom: 30px;
            }
        
            &__item {
                &:nth-last-child(-n+3)  {
                    .home__access__link {
                        margin-bottom: 30px;
                    }
                }
                &:nth-last-child(-n+2)  {
                    .home__access__link {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    // End Acces Rapides 640

    // Start Actualités 640
    .home .news {

        &__list {
            grid-template-columns: 300px;
            grid-template-rows: 430px repeat(2, 220px);
            grid-gap: 20px;
        }
    
        &__item {
    
            &__content {
                width: 300px;
                height: 220px;
            }

            &__infos {
                height: 220px;
            }

            img {
                display: none;
            }
            
            &--big {
                
                .news__item {
                    
                    &__content {
                        width: 300px;
                        height: 430px;
                    }
    
                    &__infos {
                        padding: 20px;
                        height: 210px;
                        opacity: 1;
                    }
                    
                }

                img {
                    display: block;
                    height: 220px;
                }

            }
    
        }
    
    }
    // End Actualités 640

    // Start Agenda 640
    .home .events {
        margin-bottom: 40px;
        height: 714px;

        .swiper-container {
            width: calc(100% + 16px);
            padding: 8px;
            margin-left: -8px;
            margin-top: -8px;
        }

        .swiper-slide {
            width: 300px;
            margin-right: 20px;
        }

        &__container {
            &::before {
                height: 1340px;
                top: -550px;
            }
        }

        &__navigation {
            left: 50%;
            transform: translateX(-50%);
            top: 105px;    
            z-index: 1;
        }
    }

    main .wrapper .front-page .events h2 {
        margin-bottom: 90px;
    }
    // End Agenda 640

    // Start Publications 640
    main .wrapper .front-page .documents__title {
        margin-bottom: 90px;
    }
    
    .home .documents {
        width: 300px;

        &__navigation {
            left: 50%;
            transform: translateX(-50%);
            top: 160px;
        }

        &__title {
            text-align: center;

            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    
        &__item {    
            flex-direction: column;

            &__infos {
                width: 300px;
                height: 225px;
                padding: 0 28px;
                margin: 0;
                text-align: center;
                box-sizing: border-box;
                align-items: center;
            }

            &__image__container {
                margin-bottom: 20px;
            }
    
        }
    
        &__button {
            position: relative;
            width: 300px;
            left: 0;
            bottom: 0;
            margin-top: 20px;
            border-radius: 30px 0px;
            padding: 18px 30px;
        }
    
    }
    // End Publications 640

    // Start Admin Area 640
    .admin-blocs {
        width: 300px;
        height: 480px;
    
        .bloc {

            &__image {
                width: 300px;
                height: 300px;
            }
    
            &__content {
                height: 180px;

                &::before {
                    opacity: 1;
                }
            }
            
            &__title {
                bottom: 149px;
            }
    
        }
    }
    // End Admin Area 640

}
