.cover {
    position: relative;

    &__image-wrapper {
        width: calc(((100% - 1170px) / 2) + 940px);
        height: 550px;
        background: $color-bg--image;
        position: relative;

        &::before {
            content: '';
            background: url('/wp-content/themes/frepillon/assets/src/images/motif.png');
            width: 1000px;
            height: 800px;
            position: absolute;
            right: -1000px;
            bottom: 0;
            z-index: -1;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-white;
        padding: 60px 40px;
        display: flex;
        align-items: center;
        position: absolute;
        width: 570px;
        min-height: 270px;
        border-radius: 0px 50px;
        box-shadow: $shadow;
        top: calc(50% - 40px);
        transform: translateY(-50%);
        right: calc((100% - 1170px) / 2);
        margin-bottom: 10px;
    }

    &__title {
        color: $color-main;
        font-family: $font-family--spe-heading;
        font-weight: $font-weight--spe-heading;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $color-second;
        -webkit-text-fill-color: $color-second;
        margin: 0 0 10px;
        letter-spacing: -0.01em;
        line-height: 1;
    }

    &__lead-wrapper {
        p {
            margin: 25px 0 0;
            font-weight: $font-weight-normal;
            font-size: $font-size--text-medium;
            line-height: 1.2;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            width: calc(((100% - 960px) / 2) + 745px);
            height: 480px;
        }

        &__informations {
            padding: 50px 40px;
            right: calc((100% - 940px) / 2);
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            width: 100%;
            height: 400px;

            &::before {
                right: -790px;
            }
        }

        &__informations {
            position: relative;
            margin-top: -50px;
            right: -50%;
            transform: translateX(-50%);
            width: 620px;
            height: auto;
            top: 0;
        }

        &__title {
            font-size: 2.813rem;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {        
        &__image-wrapper {
            width: 100%;
            height: 266px;

            &::before {
               display: none;
            }
        }

        &__informations {
            margin-top: -30px;
            width: 300px;
            padding: 40px 20px;
        }
    }

}
