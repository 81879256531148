.breadcrumb {
    background-color: $color-white;
    padding: 10px 0;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-text;
        padding: 0;

        a:hover {
            text-decoration: underline;
        }

        // "Vous êtes ici" style
        //&:first-child {
        //    font-size: $font-size--text-small;
        //    font-weight: $font-weight-bold;
        //    text-transform: uppercase;
        //}

        &:last-child {
            box-shadow: -1px 0px 0px rgb(32 32 34 / 10%), 1px 0px 0px rgb(32 32 34 / 10%);
        }
    }

    svg {
        width: 30px;
        height: 60px;
        fill: $color-main;

        &:last-child {
            display: none;
        }
    }
}

section[data-cpt=maps] .breadcrumb {
    padding: 10px 0;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
        } 
    }

}
