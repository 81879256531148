.footer {
    background: $color-bg-grayblue;
    
    &__infos {
        padding: 50px 0;
        color: $color-text;
        display: flex;
        justify-content: space-between;

        &__title {
            color: $color-text;
            font-size: 1.375rem;
            font-weight: $font-weight-medium;
            margin: 0 0 15px;
            line-height: 1;
        }

        &__logo {
            width: 185px;
            height: 193px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        &__coordonnees {
            width: 270px;

            .button {
                width: 220px;

                svg {
                    width: 23px;
                    height: 25px;
                }
            }
        }
        
        .footer__infos__horaires {
            width: 570px;
            display: flex;
            flex-direction: column;

            .button {
                width: 305px;
                margin-top: auto;

                svg {
                    width: 28px;
                    height: 29px;
                }
            }
        }

        &__text {
            line-height: 1.3;
        }

        &__phone {
            font-weight: $font-weight-bold;
            margin-top: 10px;
            display: block;
        }

        .button {
            margin: 30px 0 0 0;
            height: 56px;
            padding: 0;

            svg {
                margin-right: 15px;
            }
        }
    }


    &__menu {
        padding: 30px 0;
        position: relative;

        &::before {
            content: '';
            background: $color-white;
            width: 3000px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }


        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        li {
            position: relative;
            padding: 0 20px;

            a {
                &:hover {
                        color: $color-second;
                }
            } 

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 18px;
                background: $color-text;
                transform: translateY(-50%);
            }

            &:last-child::before {
                background :transparent;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {
  
        &__infos {
            
            &__logo {
                width: 136px;
                height: 141px;
                align-self: center;
            }

            .footer__infos__horaires {
                width: 480px;
            }

            &__button {
                margin: 16px 0 0 0;
            }

        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
  
        &__infos {
            padding: 210px 0 70px;
            position: relative;
            
            &__logo {
                position: absolute;
                top: 40px;
                left: 0;
            }

            .footer__infos__horaires {
                width: 300px;

                .button {
                    width: 300px;
                }
            }

            &__button {
                margin: 21px 0 0 0;
            }

        }

        &__menu {
            padding: 20px 0;

            li {
                padding: 10px 20px;

                &:nth-child(3)::before {
                    display: none;
                }
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {
  
        &__infos {
            flex-direction: column;
            text-align: center;
            
            &__logo {
                position: absolute;
                top: 40px;
                left: 50%;
                transform: translateX(-50%);
            }

            &__coordonnees {
                width: 300px;
            }

            &__horaires {
                margin-top: 30px;
            }

            .footer__infos__horaires .button {
                margin-top: 21px;
            }

            .button {
                width: 300px;
            }

        }

        &__menu li {
            padding: 10px 15px;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4) {
                &::before {
                    display: none;
                }
            }

            &:nth-child(3)::before {
                display: block;
            }
        }
    }

}