.header {
    position: relative;
    padding-top: 49px; // hauteur des tools

    .identity {
        &__title {
            font-size: 0;

            svg {
                width: 293px;
                height: 91px;
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        position: relative;

        &::before {
            content: '';
            width: 260px;
            height: 726px; // hauteur forme sur la home
            background: $color-bg-gradient-menu;
            opacity: 0.9;
            transform: skewX(-22deg);
            position: absolute;
            right: 105px;
            top: -49px;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
    }
}




//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {
    position: absolute;
    top: -49px;
    right: 0;
    z-index: 11;

    .container--tools {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background-color: $color-third;
        border: 2px solid $color-third;
        height: 49px;
        margin-top: 0;
        margin-bottom: 0;

        svg {
            fill: $color-text;
            transition: all $duration;
        }

        &:hover {
            cursor: pointer;
            background: $color-white;

            svg {
                fill: $color-text;
            }
        }
    }

    &__search {
        text-transform: uppercase;
        margin-right: 10px;
        border-radius: 0px 0px 0px 30px;
        width: 178px;

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        svg {
            width: 17px;
            height: 17px;
            margin-right: 10px;
        }
    }

    &__accessibility {
        margin-left: 10px;
        margin-right: 0;
        border-radius: 0px 0px 30px 0px;
        width: 61px;

        svg {
            width: 25px;
            height: 23px;
        }
    }

}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}


//======================================================================================================
// Header Fixed
//======================================================================================================

.header {
    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        background-color: $color-white;
        box-shadow: $shadow;
        z-index: 20;
        height: 80px;
        
        .identity {    
            h1 {
                svg {
                    width: 167px;
                    height: 52px;
                }
            }
        }

        .header {

            &__container {
                &::before {
                    height: 130px;
                    width: 250px;
                    right: 135px;
                }
            }

            &__top {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
                z-index: 20;
            }

        }
        
        .tools {
            top: 50%;
            transform: translateY(-50%);

            .container--tools {
                padding: 0;
            }
 
            &::before {
                display: none;
            }

            .tool {
                display: none;
            }

            &__search.tool {
                display: flex;
                width: 67px;
                height: 47px;
                border-radius: 30px 0px;
                margin: 0;

                span {
                    display: none;
                }
    
                svg {
                    margin: 0;
                }
            }

        }

    }
}



//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

    .header {
    
        .identity {
            h1 {
                svg {
                    width: 220px;
                    height: 68px;
                }
            }
        }
    
        &__container {    
            &::before {
                width: 225px;
                height: 633px; // hauteur forme sur la home
                right: 90px;
            }
        }

        &--fixed {
            .identity {
                h1 {
                    svg {
                        width: 150px;
                        height: 46px;
                    }
                }
            }

            .header__container {
                &::before {
                    height: 130px;
                    width: 230px;
                    right: 100px;
                }
            }
        }
    
    }

}


// 960
@media screen and (max-width: $medium) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  

    .header {
 
        &__container {
            &::before {
                height: 400px;
            }
        }

        .identity {
            h1 {
                svg {
                    width: 270px;
                    height: 84px;
                    margin-top: -20px;
                }
            }
        }
 
    }   

}


// 640
@media screen and (max-width: $small) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }

    .tools {
        right: 50%;
        transform: translateX(50%);
    }

    .tools-view {
        .container--tools-view {
            .profils {
                margin-top: 80px;
                margin-right: 35px;
            }
        }
    }

    .header {
        &__container {
            &::before {
                height: 44px;
                bottom: 26px;
                top: unset;
                left: 50%;
                transform: translateX(-50%) skewX(-22deg);
            }
        }

        &__bottom {
            flex-direction: column;
        }

        .identity {
            h1{
                svg {
                    margin: 30px 0 25px 0;
                }
            }
        }
    }

}
