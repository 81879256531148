#nav-main {
    // position: relative;
    z-index: 10;

    .container__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .nav-main-link {
                &:before {
                    transform: skewX(-22deg) translate(calc(-50% + 10px),0);
                }
            }

            &:first-child {
                .nav-main-link {
                    &:before {
                        transform: skewX(-22deg) translate(calc(-50% + 31.5px));
                    }
                }
            }
            &:last-child {
                .nav-main-link {
                    color: $color-text;

                    &:before {
                        transform: skewX(-22deg) translate(calc(-50% + 44px));
                    }
                }
            }

            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:first-child {
           .nav-main-link {
                padding: 0 40px 0 80px;

                &:before {
                    width: calc(100% - 43px);
                    transform: skewX(-22deg) translate(calc(-50% + 31.5px), -176px);
                }
                
            }
        }
        &:last-child {
           .nav-main-link {
                color: $color-white;
                padding: 0 40px 0 90px;

                &:before {
                    width: 260px;
                    transform: skewX(-22deg) translate(calc(-50% + 44px), -176px);
                }
                
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            max-width: 350px;
            height: 127px;
            padding: 0 40px;
            color: $color-text;
            text-transform: uppercase;
            font-size: 1.375rem;
            font-weight: $font-weight-bold;
            line-height: 1.1;
            
            > span {
                font-size: 1.250rem;
                font-weight: $font-weight-light;
            }

            &:before {
                content: '';
                width: 100%;
                height: 176px;
                background: $color-main;
                opacity: 0.9;
                position: absolute;
                top: -49px;
                left: 50%;
                z-index: -1;
                transform: skewX(-22deg) translate(calc(-50% + 10px),-176px);
                transition: all 0.3s;
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: calc((100vw - 1200px) / -2);
            width: 100vw;
            padding: 30px 15px;
            background: $color-bg-grayblue;
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 60px;
                    padding: 15px 30px 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    border-bottom: 1px solid $color-main;
                    color: $color-text;
                    font-weight: $font-weight-bold;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        bottom: 0;
                        width: 0;
                        height: 1px;
                        background: $color-main;
                        transition: all $duration;
                    }

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 10px;
                        color: $color-main;
                        transition: all $duration;
                    }

                    &:hover {
                        padding: 15px 30px 15px 15px;
                        background: $color-main;

                        &::before {
                            width: calc(100% - 15px);
                        }
                        &::after {
                            color: $color-text;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 17px 10px 17px 35px;
                    border-bottom: 0;
                    font-weight: $font-weight-normal;
                    text-transform: initial;
                    line-height: initial;

                    &::before {
                        display: none;
                    }
                    &::after {
                        right: unset;
                        left: 5px;
                    }

                    &:hover {
                        padding: 17px 10px 17px 35px;

                        &::after {
                            left: 10px;
                        }
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-white;

    .navicon {
        position: relative;
        display: block;
        height: 3px;
        top: -1px;
        width: 20px;
        margin-right: 15px;
        background: $color-white;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-white;
            transition: all $duration ease-out;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}

.header--fixed {
    
    #nav-main {
        .nav-main-item {

            .nav-main-link {
                height: 80px;
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                &:last-child {
                    .nav-main-link {
                        &:before {
                            transform: skewX(-22deg) translate(calc(-50% + 25px));
                        }
                    }
                }
            }

            &:last-child {
                .nav-main-link {
                    &:before {
                        width: 250px;
                        transform: skewX(-22deg) translate(calc(-50% + 25px), -176px);
                    }
                }
            }

        }
    }

}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        .nav-main-item {
            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {

                .nav-main-link {
                    &:before {
                        transform: skewX(-22deg) translate(calc(-50% + 10px),0);
                    }
                }

                &:first-child {
                    .nav-main-link {
                        &:before {
                            transform: skewX(-22deg) translate(calc(-50% + 20.5px));
                        }
                    }
                }
    
                &:last-child {
                    .nav-main-link {
                        &:before {
                            transform: skewX(-22deg) translate(calc(-50% + 22px));
                        }
                    }
                }

            }
            
            .nav-main-link {
                height: 114px;
                padding: 0 22px;

                &:before {
                    height: 163px;
                    left: 50%;
                    transform: skewX(-22deg) translate(calc(-50% + 10px),-163px);
                }
            }

            &:first-child {
                .nav-main-link {
                    padding: 0 42px 0 48px;
    
                    &:before {
                        width: calc(100% - 25px);
                        transform: skewX(-22deg) translate(calc(-50% + 20.5px), -163px);
                    }
                }
            }

            &:last-child {
                .nav-main-link {
                    padding: 0 30px 0 57px;
    
                    &:before {
                        width: 225px;
                        transform: skewX(-22deg) translate(calc(-50% + 22px), -163px);
                    }
                }
            }

            .sub-menu {
                left: calc((100vw - 960px) / -2);
            }
        }
    }

    .header--fixed {

        #nav-main {
            .nav-main-item {

                &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {

                    &:first-child {
                        .nav-main-link {
                        }
                    }

                    &:last-child {
                        .nav-main-link {
                            &::before {
                                transform: skewX(-22deg) translate(calc(-50% + 5px));
                            }
                        }
                    }

                }
                
                &:first-child {
                    .nav-main-link {
                        padding: 0 20px 0 40px;
                    }
                }

                &:last-child {
                    .nav-main-link {
                        padding: 0 50px 0 60px;

                        &::before {
                            width: 230px;
                            transform: skewX(-22deg) translate(calc(-50% + 5px), -163px);
                        }
                    }
                }

                .nav-main-link {
                    padding: 0 20px;
                }

            }
        }
        
    }

}


// 960
@media screen and (max-width: $medium) {
    
    #nav-main {
        // position: relative;
        
        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 114px;
            left: 10px;
            width: 620px;
            // margin: auto -15px;
            background: $color-main;
        }
        
        .nav-main-item {
            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-text;
                color: $color-text;
                text-transform: uppercase;
                
                &:first-child {
                    padding: 0 20px;
                }
                
                &:before {
                    display: none;
                }
            }
            
            &:last-child {
                .nav-main-link {
                    border-bottom: 0;
                    color: $color-text;
                    
                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }
            
            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }
            
            &.active,
            &.menu-item-has-children.active {
                background: $color-main;
                box-shadow: 0 0 1px rgb(27 35 54 / 8%), 1px 1px 10px rgb(27 35 54 / 8%);
                
                .nav-main-link {
                    border-bottom: 1px solid $color-text;
                    color: $color-text;
                }
                
                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 5px 10px;
                    box-shadow: none;
                    left: 0;
                    width: 100%;
                    
                    .nav-drop {
                        column-count: 1;
                    }
                    
                    .nav-grandchild-item {
                        a {
                            padding: 10px 10px 10px 35px;
                        }
                    }
                    
                    li:last-child {
                        a {
                            border-bottom: 0;
                            
                            &::before {
                                display: none;
                            }
                            
                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }
            
        }
        
        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }
            
            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;
                        
                        &:before {
                            transform: rotate(-45deg);
                        }
                        
                        &:after {
                            transform: rotate(45deg);
                        }
                    }
                    
                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
        
        // Responsive button label
        .nav-main_icon {
            display: flex;
            width: 100%;
            height: 114px;
            margin: 0 0 0 145px;
            padding: 0;
            font-size: 1.375rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase; 
        }
    }
    
}


// 1200
@media screen and (max-width: $small) {
    #nav-main {
        .nav-main_icon {
            height: 45px;
            margin: 0 0 25px 0;
        }

        .menu__nav__list {
            top: 183px;
            width: 300px;
        }
    }
}